export const environment = {
  production: false,
  cognitoRegion: 'eu-west-1',
  cognitoUserPoolId: 'eu-west-1_YuPXNYFVp',
  cognitoClientId: '347fbofg5sp8nrf82058k5n2mu',
  cognitoIdentityPoolId: '',
  cognitoOAuthDomain: 'cognito.dev.heitown.com',
  apiBaseUrl: 'https://api.dev.heitown.com/api',
  environment: 'dev',
  stripePublishableKey: 'pk_test_sGMempehRuf4t9fSH0FPeVbb',
  tsIntegration: true,
};

/*comment for CI*/
